<template>
  <!-- 财务信息echarts封装 -->
  <div class="financialEcharts">
    <!-- <p class="bombBox-content_v2-subtitle" >财务信息</p> -->
    <div class="selectAndChart">
      <div class="chartSelect">
        <div
          :class="
            chartSelect == 0 ? 'chartSelect-itemSelected' : 'chartSelect-item'
          "
          @click="handleChartSelect(0)"
        >
          营业收入
        </div>
        <div
          :class="
            chartSelect == 1 ? 'chartSelect-itemSelected' : 'chartSelect-item'
          "
          @click="handleChartSelect(1)"
        >
          规上净利润
        </div>
        <div
          :class="
            chartSelect == 2 ? 'chartSelect-itemSelected' : 'chartSelect-item'
          "
          @click="handleChartSelect(2)"
        >
          现金流量净额
        </div>
        <div
          :class="
            chartSelect == 3 ? 'chartSelect-itemSelected' : 'chartSelect-item'
          "
          @click="handleChartSelect(3)"
        >
          总资产
        </div>
        <div
          :class="
            chartSelect == 4 ? 'chartSelect-itemSelected' : 'chartSelect-item'
          "
          @click="handleChartSelect(4)"
        >
          规上净资产
        </div>
      </div>
      <div class="lineChartFinancial" ref="lineChart"></div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../utils/eventBus.js";

export default {
  name: "FinancialEcharts",
  props: {
    // msg: String,
    // lineChartYearList: Array,
    // lineChartData: Array,
    // lineChartDataAllType: Object,
    apiDataLineChart: Array,
  },
  data() {
    return {
      chartSelect: 0,
      lineChart: "",
      lineChartYearList: [],
      lineChartData: [],
      lineChartDataAllType: {
        income: [],
        shareholder_profit: [],
        cash_flow: [],
        asset: [],
        shareholder_asset: [],
      },
    };
  },
  watch: {
    // 监听父组件传值
    apiDataLineChart: {
      deep: true,
      handler(newVal, oldVal) {
        console.log("newValFinancial", newVal);
        if (this.lineChart) {
          echarts.init(this.$refs.lineChart).dispose();
        }
        this.chartSelect = 0;
        this.lineChartYearList = [];
        this.lineChartData = [];
        this.lineChartDataAllType = {
          income: [],
          shareholder_profit: [],
          cash_flow: [],
          asset: [],
          shareholder_asset: [],
        };
        newVal.forEach((i, index) => {
          if (index == 0) {
            this.lineChartYearList.push((i.year - 1).toString());
            this.lineChartYearList.push(i.year);
            this.lineChartDataAllType.income.push(i.income_last_year);
            this.lineChartDataAllType.income.push(i.income_this_year);
            this.lineChartDataAllType.shareholder_profit.push(
              i.shareholder_profit_last_year
            );
            this.lineChartDataAllType.shareholder_profit.push(
              i.shareholder_profit_this_year
            );
            this.lineChartDataAllType.cash_flow.push(i.cash_flow_last_year);
            this.lineChartDataAllType.cash_flow.push(i.cash_flow_this_year);
            this.lineChartDataAllType.asset.push(i.asset_last_year);
            this.lineChartDataAllType.asset.push(i.asset_this_year);
            this.lineChartDataAllType.shareholder_asset.push(
              i.shareholder_asset_last_year
            );
            this.lineChartDataAllType.shareholder_asset.push(
              i.shareholder_asset_this_year
            );
          } else {
            this.lineChartYearList.push(i.year);
            this.lineChartDataAllType.income.push(i.income_this_year);
            this.lineChartDataAllType.shareholder_profit.push(
              i.shareholder_profit_this_year
            );
            this.lineChartDataAllType.cash_flow.push(i.cash_flow_this_year);
            this.lineChartDataAllType.asset.push(i.asset_this_year);
            this.lineChartDataAllType.shareholder_asset.push(
              i.shareholder_asset_this_year
            );
          }
        });
        // 去掉 , 逗号
        for (let key in this.lineChartDataAllType) {
          this.lineChartDataAllType[key] = this.lineChartDataAllType[key].map(
            (val) => {
              if (val != null) {
                return val.replace(/,/g, "");
              } else {
                return "0";
              }
            }
          );
        }
        this.lineChartData = this.lineChartDataAllType.income;
        console.log("lineChartData", this.lineChartData);
        this.$nextTick(() => {
          if (this.lineChartDataAllType.income.length != 0) {
            this.setOriginLineChart();
            this.setLineChart();
          }
        });
      },
    },
  },
  methods: {
    // 监听重新渲染
    listenFinancialRerender() {
      EventBus.$on("financial_rerender", (data) => {
        if (this.lineChart) {
          this.$nextTick(() => {
            echarts.init(this.$refs.lineChart).resize();
          });
        }
      });
    },
    // chart选择tab
    handleChartSelect(val) {
      this.chartSelect = val;
      switch (val) {
        case 0:
          this.lineChartData = this.lineChartDataAllType["income"];
          console.log(this.lineChartData);
          break;
        case 1:
          this.lineChartData = this.lineChartDataAllType["shareholder_profit"];
          console.log(this.lineChartData);
          break;
        case 2:
          this.lineChartData = this.lineChartDataAllType["cash_flow"];
          break;
        case 3:
          this.lineChartData = this.lineChartDataAllType["asset"];
          break;
        case 4:
          this.lineChartData = this.lineChartDataAllType["shareholder_asset"];
          break;
        default:
          break;
      }
      this.$nextTick(() => {
        this.setLineChart();
      });
    },
    // 折线图初始化
    setOriginLineChart() {
      this.lineChart = echarts.init(this.$refs.lineChart);
    },
    // 折线图数据渲染
    setLineChart() {
      let option = {
        itemStyle: {
          color: "#01a4d9",
          opacity: 0.8,
        },
        grid: {
          left: "7%",
          right: "7%",
          top: "16%",
          bottom: "5%",
          containLabel: true,
        },
        label: {
          show: true,
          formatter: function (params) {
            // console.log("formatter", params)
            return (params.value / 100000000).toFixed(2) + "亿";
            // if (Math.abs(params.value) < 100000000) {
            //   return (params.value / 10000000).toFixed(2) + "千万";
            // } else {
            //   return (params.value / 100000000).toFixed(2) + "亿";
            // }
          },
        },
        textStyle: {
          color: "#333333",
        },
        tooltip: {
          trigger: "axis",
          // formatter: "{c}元",
          formatter: function (params) {
            return (params[0].value / 100000000).toFixed(2) + "亿";
          },
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          // data: ["2017", "2018", "2019", "2020"],
          data: this.lineChartYearList,
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            data: this.lineChartData,
            type: "line",
            areaStyle: {},
          },
        ],
      };
      this.lineChart.setOption(option);
    },
    echartResize() {
      if (this.lineChart) {
        echarts.init(this.$refs.lineChart).resize();
      }
    },
  },
  mounted() {
    this.listenFinancialRerender();
    // this.setOriginLineChart();
    // let timer = null;
    // window.onresize = () => {
    //   console.log("resize");
    //   if (!timer) {
    //     timer = setTimeout(() => {
    //       console.log("窗口变化ffff");
    //       // if (this.lineChart) {
    //       //   echarts.init(this.$refs.lineChart).resize();
    //       // }
    //       this.lineChart.resize()
    //       // this.wordCloud.resize()
    //       timer = null;
    //     }, 500);
    //   }
    // };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss">
.financialEcharts {
  .selectAndChart {
    display: flex;
    margin-left: 20px;
    margin-top: 20px;
  }
  .lineChartFinancial {
    width: 75%;
    margin-left: 10px;
    height: 8vw;
    border: 1px solid rgb(179, 179, 182);
  }
  .chartSelect {
    width: 20%;
    height: 8vw;
    border-top: 1px solid rgb(179, 179, 182);
    // border-bottom: none;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    &-item,
    &-itemSelected {
      border-bottom: 1px solid rgb(179, 179, 182);
      border-right: 1px solid rgb(179, 179, 182);
      border-left: 1px solid rgb(179, 179, 182);
      height: 19.8%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 13px;
      // padding: 2px 0 2px 0;
      // &:hover {
      //   background-color: #01a4d9;
      // }
    }
    &-itemSelected {
      background-color: #01a4d9;
      color: white;
    }
  }
}
</style>
