<template>
  <div class="productEcharts">
    <!-- <p
      class="bombBox-content_v2-subtitle"
      v-if="lineChart2DataAllType['income'].length != 0"
    >
      营收构成
    </p> -->
    <div class="selectAndChart">
      <div
        class="chartSelect2"
        v-if="lineChart2DataAllType['income'].length != 0"
      >
        <div
          :class="
            chartSelect2 == 0 ? 'chartSelect-itemSelected' : 'chartSelect-item'
          "
          @click="handleChartSelect2(0)"
        >
          营业收入
        </div>
        <div
          :class="
            chartSelect2 == 1 ? 'chartSelect-itemSelected' : 'chartSelect-item'
          "
          @click="handleChartSelect2(1)"
        >
          营业利润
        </div>
      </div>
      <div
        class="lineChart2"
        ref="lineChart2"
        v-if="lineChart2DataAllType['income'].length != 0"
      ></div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../utils/eventBus.js";

export default {
  name: "ProductEcharts",
  props: {
    apiDataLineChart: Object,
    apiYearList: Array,
  },
  data() {
    return {
      lineChart2: "",
      chartSelect2: 0,
      lineChart2YearList: [],
      product_legend: [],
      lineChartData2: [],
      lineChart2DataAllType: {
        income: [],
        profit: [],
      },
    };
  },
  watch: {
    apiDataLineChart: {
      deep: true,
      handler(newVal, oldVal) {
        if (this.lineChart2) {
          echarts.init(this.$refs.lineChart2).dispose()
        }
        this.chartSelect2 = 0;
        this.lineChart2DataAllType = {
          income: [],
          profit: [],
        };
        this.lineChart2YearList = this.apiYearList
        for (let key in newVal) {
          // this.product_legend.push(key);
          let val_income = [];
          let val_profit = [];
          for (let k in newVal[key]) {
            val_income.push(newVal[key][k].income.toString().replace(/,/g, ""));
            val_profit.push(newVal[key][k].profit.toString().replace(/,/g, ""));
          }
          this.lineChart2DataAllType.income.push({
            name: key,
            type: "line",
            stack: "总量",
            areaStyle: {},
            data: val_income,
          });
          this.lineChart2DataAllType.profit.push({
            name: key,
            type: "line",
            stack: "总量",
            areaStyle: {},
            data: val_profit,
          });
        }
        this.lineChartData2 = this.lineChart2DataAllType.income;
        console.log("渲染数据", this.lineChartData2)
        this.$nextTick(() => {
          if (this.lineChart2DataAllType.income.length != 0) {
            console.log("渲染")
            this.setOriginLineChart2();
            this.setLineChart2();
          }
        });
      },
    },
  },
  methods: {
    // 监听重新渲染
    listenProductRerender() {
      EventBus.$on("product_rerender", data => {
        if (this.lineChart2) {
          this.$nextTick(() => {
            echarts.init(this.$refs.lineChart2).resize();
          })
        }
      })
    },
    // 点击tab切换显示类型
    handleChartSelect2(val) {
      this.chartSelect2 = val;
      switch (val) {
        case 0:
          this.lineChartData2 = this.lineChart2DataAllType["income"];
          break;
        case 1:
          this.lineChartData2 = this.lineChart2DataAllType["profit"];
          break;
        default:
          break;
      }
      this.setLineChart2();
    },
    // echart初始化
    setOriginLineChart2() {
      this.lineChart2 = echarts.init(this.$refs.lineChart2);
    },
    // 渲染echart数据
    setLineChart2() {
      let option = {
        //  color: ["#409EFF", "#18c5cc", "#7fbeff"],
        // textStyle: {
        //   color: "white",
        // },
        // title: {
        //     text: '堆叠区域图'
        // },
        tooltip: {
          confine: "false",
          appendToBody: "true",
          extraCssText: "max-width: 400px;white-space:pre-wrap;z-index:3000",
          position: function (point, params, dom, rect, size) {
            return [point[0], 0];
          },
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },

        legend: {
          // data: ["产品1", "产品2", "产品3"],
          // data: this.product_legend,
          type: "scroll",
          textStyle: {
            color: "#333333",
          },
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.lineChart2YearList,
            // data: ["2018", "2019"],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: this.lineChartData2,
      };
      this.lineChart2.setOption(option);
    },
    echartResize() {
      if (this.lineChart2) {
        echarts.init(this.$refs.lineChart2).resize();
      }
    }
  },
  mounted() {
    this.listenProductRerender()
    // let timer = null;
    // window.onresize = () => {
    //   console.log("resize");
    //   if (!timer) {
    //     timer = setTimeout(() => {
    //       console.log("窗口变化pppp");
    //       // if (this.lineChart2) {
    //       //   echarts.init(this.$refs.lineChart2).resize();
    //       // }
    //       this.lineChart2.resize()
    //       // this.wordCloud.resize()
    //       timer = null;
    //     }, 500);
    //   }
    // };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.productEcharts {
   .selectAndChart {
    display: flex;
    margin-left: 20px;
    margin-top: 20px;
  }
   .chartSelect,
  .chartSelect2 {
    width: 20%;
    height: 8vw;
    border-top: 1px solid rgb(179, 179, 182);
    // border-bottom: none;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    &-item,
    &-itemSelected {
      border-bottom: 1px solid rgb(179, 179, 182);
      border-right: 1px solid rgb(179, 179, 182);
      border-left: 1px solid rgb(179, 179, 182);
      height: 15.8%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 13px;
      // padding: 2px 0 2px 0;
      // &:hover {
      //   background-color: #01a4d9;
      // }
    }
    &-itemSelected {
      background-color: #01a4d9;
      color: white;
    }
  }
  .chartSelect2 {
    width: 20%;
    height: 10vw;
    margin-top: 1.5vw;
  }
  .lineChart2 {
    width: 75%;
    margin-left: 10px;
    height: 13vw;
    border: 1px solid rgb(179, 179, 182);
  }
}
</style>
